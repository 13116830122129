import UserContactInfoScreen from "@/pages/contactInfoScreen/UserContactInfoScreen";
import NewConversationScreen from "@/pages/conversationsScreen/NewConversationScreen";
import NewConversationScreenHeader from "@/pages/conversationsScreen/NewConversationScreenHeader";
import WebGwContact from "@/utils/helpers/WebGwContact";
import { useToast } from "@/utils/helpers/toastManager";
import { useBlock } from "@/utils/hooks/useBlock";
import {
  CapabilityType,
  useCapabilitiesFromServerOnInit,
  useCapabilitiesOnCacheUpdate,
} from "@/utils/hooks/useCapabilities";
import { useChatbotInfo } from "@/utils/hooks/useChatbotInfo";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { useSelectedConversation } from "@/utils/messaging/conversation/ConversationState";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, {
  Suspense,
  lazy,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Box } from "../../pages/layout/Layout.style";
import { colors } from "../../styles/global.styles";
import { noop, sleep } from "../../utils";
import ContactsList, {
  ContactSelectHandler,
} from "../contactListScreen/ContactsList";
import { BlockedContact } from "../contacts/BlockedContact";
import { IconButton } from "../shared/Button";
import { ThreeLinesLoaderBox } from "../shared/Loaders/ThreeLines";
import SlideOnTop from "../shared/SlideOnTop";
import { ChatFooterRef } from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ChatInfo from "./ChatInfo";
import TypeEditOverlay from "./TypeEditOverlay";
import TypeReplyOverlay from "./TypeReplyOverlay";
import TypingIndicator from "./chat/messages/TypingIndicator";
import { multiCloseDelay } from "./chat/util/chatUtils";
const ChatBox = lazy(() => import("./ChatBox"));
const ChatFooter = lazy(() => import("./ChatFooter"));

type Props = { conversation?: Conversation | null } & {
  nestedChat?: boolean;
  onRemoveContact?: (
    contactId: string,
    currentAddContactInputValue?: string
  ) => void;
  closeChat?: () => void;
  openContactInfoOverride?: () => void;
  isConversationStarted?: boolean;
  contacts?: WebGwContact[];
  contactSelectHandler?: ContactSelectHandler;
  setShowCreateGroupChat?: (show: boolean) => void;
  onConversationStarted?: (conversationId: string) => void;
  onShowContactPicker?: (show: boolean) => void;
  onAddNumber?: (number: string) => void;
  showLoadingPhoneIcon?: boolean;
  searchContactQuery?: string;
  setSearchContactQuery?: (string) => void;
  isShown?: boolean;
  autoFocusInput?: boolean;
  addContactInputDefaultValue?: string;
};

export type ChatScreenHandle = {
  closeInfos?: () => Promise<void>;
  chatFooter: ChatFooterRef | null;
};

export default function ChatScreen({
  ref,
  nestedChat,
  closeChat,
  conversation: _conversation,
  openContactInfoOverride,
  onRemoveContact = noop,
  isConversationStarted = true,
  contacts = [],
  contactSelectHandler,
  setShowCreateGroupChat,
  onConversationStarted,
  onShowContactPicker,
  onAddNumber,
  showLoadingPhoneIcon,
  searchContactQuery,
  setSearchContactQuery,
  isShown = true,
  autoFocusInput = true,
  addContactInputDefaultValue,
}: Props & {
  ref?: React.RefObject<ChatScreenHandle>;
}) {
  const selectedConversation = useSelectedConversation({
    enabled: _conversation == null,
  });
  const conversation = _conversation || selectedConversation;

  const savedContacts = useContacts();
  const hasContacts = (savedContacts?.length ?? 0) > 0;

  const isGroupChat = conversation?.getIsGroupChat();
  const firstParticipant = contacts[0] || conversation?.participants[0];
  const isChatbot = !!firstParticipant?.isChatbot;
  // By default we assume single conversation are with rcs number
  const [isRcs, setIsRcs] = useState(true);
  const chatbotNotAvailableShown = useRef(false);

  // ? maybe there should be more logic for this like looking through the list of contacts
  const renderContactInfo =
    !!firstParticipant?.id || isGroupChat || firstParticipant?.isChatbot;
  const isOdienceFrontRow = firstParticipant?.isOdienceFrontRow();

  const [chatInfoOpen, setChatInfoOpen] = useState(false);
  const { showToast } = useToast();
  const [contactBlocked, setContactBlocked] = useState(false);

  const closeChatInfo = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setChatInfoOpen(false);
  };
  const openChatInfo = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setChatInfoOpen(true);
  };

  const [contactInfoOpen, setContactInfoOpen] = useState(false);

  const block = useBlock(firstParticipant, contactBlocked, setContactBlocked, [
    chatInfoOpen,
  ]);

  const closeContactInfo = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    setContactInfoOpen(false);
  };
  const openContactInfo = renderContactInfo
    ? (openContactInfoOverride ??
      ((e?: React.MouseEvent) => {
        e?.stopPropagation();
        setContactInfoOpen(true);
      }))
    : undefined;

  const closeInfos =
    contactInfoOpen || chatInfoOpen
      ? async (e?: React.MouseEvent) => {
          if (contactInfoOpen) {
            closeContactInfo(e);
            await sleep(multiCloseDelay);
          }
          if (chatInfoOpen) {
            closeChatInfo(e);
            await sleep(multiCloseDelay);
          }
        }
      : undefined;

  const s1 = useRef<HTMLDivElement>(null!);
  const s2 = useRef<HTMLDivElement>(null);

  const chatFooterRef = useRef<ChatFooterRef>(null);

  useImperativeHandle(ref, () => ({
    closeInfos,
    get chatFooter() {
      return chatFooterRef.current;
    },
  }));

  /**
   * On ui side, there is no caps check to start a conversation for regular 1-1, this way we can open the view right away and we allow sending out messages:
   * - when opening the conversation, we request the caps for the user. Only for 1-1, group and chatbot we never check caps.
   * - the hook will then call [callback] if [condition] is met
   * Under the hood, the hook sets the [hasCapability] to:
   * - local caps in cache if known
   * - true if server does not know the number yet
   * - the capability value from the server otherwise
   *
   * The second use effect below will update according to refreshed caps from server.
   *
   */
  useCapabilitiesFromServerOnInit({
    condition: !!(!isGroupChat && !isChatbot && conversation),
    // Always use the conversation id which is the phone number that has the most chances to be in the correct format
    phoneNumber: conversation?.id,
    capabilityType: CapabilityType.MESSAGING,
    callback: (hasCapability: boolean) => {
      setIsRcs(hasCapability);
    },
  });

  /**
   * This will update the rcs flag anytime we are getting caps for the number
   */
  useCapabilitiesOnCacheUpdate({
    condition: !!(!isGroupChat && !isChatbot && conversation),
    phoneNumber: conversation?.id,
    capabilityType: CapabilityType.MESSAGING,
    callback: (hasCapability: boolean) => {
      setIsRcs(hasCapability);
    },
  });

  const { isError, data: info } = useChatbotInfo(firstParticipant);

  useEffect(() => {
    if (
      !chatbotNotAvailableShown.current &&
      isShown &&
      isChatbot &&
      (isError || info === null)
    ) {
      showToast(
        "This bot may not be available at the moment. You may not receive responses.",
        "bottom-right"
      );

      chatbotNotAvailableShown.current = true;
    }
  }, [isShown, isError, info]);

  return (
    <>
      {conversation ? (
        <Box
          css={{
            color: colors.primaryTextColor,
            position: "relative",
            padding: "0",
            ...(nestedChat && {
              width: "100%",
              height: "100%",
            }),
          }}
          onClick={(e) => {
            if (!contactInfoOpen && !chatInfoOpen) return;
            if (
              [s1.current, s2.current].some((elem) =>
                elem?.contains(e.target as Node)
              )
            ) {
              return;
            }
            void closeInfos?.(e);
          }}
        >
          {isConversationStarted ? (
            <ChatHeader openChatInfo={openChatInfo} closeChat={closeChat} />
          ) : (
            <>
              <NewConversationScreenHeader
                contacts={
                  firstParticipant ? [firstParticipant] : ([] as WebGwContact[])
                }
                setSearchQuery={(number) => setSearchContactQuery?.(number)}
                onRemoveContact={onRemoveContact}
                onShowContactPicker={onShowContactPicker!}
                onAddNumber={onAddNumber}
                showLoadingPhoneIcon={showLoadingPhoneIcon}
                closeCreateGroupChat={
                  setShowCreateGroupChat
                    ? () => setShowCreateGroupChat(false)
                    : undefined
                }
                defaultValue={addContactInputDefaultValue}
              />
              {searchContactQuery && hasContacts && (
                <ContactsList
                  searchQuery={searchContactQuery}
                  ignoreContacts={contacts}
                  contactSelectedHandler={contactSelectHandler}
                  fromConversationScreen={true}
                />
              )}
            </>
          )}
          <Suspense fallback={<ThreeLinesLoaderBox />}>
            <ChatBox chatFooterRef={chatFooterRef} />
            <hr css={{ height: "1px", margin: "0", width: "100%" }} />
            <TypingIndicator />
            <TypeReplyOverlay chatFooterRef={chatFooterRef} />
            <TypeEditOverlay chatFooterRef={chatFooterRef} />
            {block.value === "Unblock" ? (
              <BlockedContact
                handleUnblockContact={block.show}
                contact={firstParticipant}
              />
            ) : (
              !isOdienceFrontRow && (
                <ChatFooter
                  ref={chatFooterRef}
                  onConversationStarted={onConversationStarted}
                  isRcs={!!isRcs}
                  isConversationStarted={isConversationStarted}
                  autoFocusInput={autoFocusInput}
                />
              )
            )}
          </Suspense>
          <SlideOnTop
            ref={s1}
            open={chatInfoOpen}
            layer={1}
            onClick={contactInfoOpen ? closeContactInfo : undefined}
          >
            <ChatInfo
              conversation={conversation}
              closeChatInfo={closeChatInfo}
              isChatbot={isChatbot}
              openContactInfo={openContactInfo}
              setShowCreateGroupChat={setShowCreateGroupChat}
              chatInfoOpen={chatInfoOpen}
              contactBlocked={contactBlocked}
              onContactBlocked={setContactBlocked}
            />
          </SlideOnTop>

          {renderContactInfo &&
            (isChatbot ? (
              <SlideOnTop ref={s2} open={contactInfoOpen} layer={2}>
                <ChatInfo
                  conversation={conversation}
                  closeChatInfo={closeContactInfo}
                  isChatbot={true}
                />
              </SlideOnTop>
            ) : (
              <SlideOnTop ref={s2} open={contactInfoOpen} layer={2}>
                <UserContactInfoScreen
                  webgwContact={conversation.participants[0]}
                  style={{ width: "100%", height: "100%" }}
                  customHeaderChildren={
                    <IconButton
                      onClick={closeContactInfo}
                      css={{ color: colors.primaryTextColor }}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  }
                />
              </SlideOnTop>
            ))}
          {block.modal}
        </Box>
      ) : (
        <NewConversationScreen
          onConversationStarted={onConversationStarted}
          contacts={contacts}
          onRemoveContact={onRemoveContact}
          contactSelectHandler={contactSelectHandler!}
          onShowContactPicker={onShowContactPicker!}
          onAddNumber={onAddNumber}
          showLoadingPhoneIcon={showLoadingPhoneIcon}
          searchContactQuery={searchContactQuery || ""}
          setSearchContactQuery={(number) => setSearchContactQuery?.(number)}
          onShowStartGroupConversation={noop}
          showStartGroupConversation={false}
        />
      )}
    </>
  );
}
